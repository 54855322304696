<template>
  <v-card
      class="mt-6 pb-3"
      :disabled="isLoading"
  >
    <v-card-title class="question-card-title px-6 font-weight-bold">
      {{ question.name }}
    </v-card-title>
    <v-card-text class="mt-9">
      <v-row>
        <v-col
            class="py-0"
        >
          <v-slider
              v-model="value"
              hide-details
              :thumb-size="thumbSize"
              :step="question.step"
              :ticks="question.ticks"
              :color="color(value)"
              :tick-size="tickSize(question.ticks)"
              :max="question.range[1]"
              :min="question.range[0]"
              thumb-label="always"
              @change="update"
          ></v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            class="text-left py-0 px-5"
        >
          <label>{{ question.tick_labels[0] }}</label>
        </v-col>
        <v-col
            class="text-right py-0 px-5"
        >
          <label>{{ question.tick_labels[1] }}</label>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'QuestionCard',
  props: {
    isLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    question: {
      type: Object,
      default: () => {
        return {
          metric: 'pain',
          question: 'Pain',
          range: [0, 100],
          colors: [['green', 33],['red', 66]],
          step: 100,
          ticks: false,
          ticksLabels: ['Not at all', 'Severe']
        }
      }
    }
  },
  data: () => ({
    thumbSize: 36,
    value: 0,
  }),
  methods: {
    tickSize (ticks) {
      if (ticks) {
        return 12
      } else {
        return 2
      }
    },
    color (value) {
      if (typeof value !== 'undefined') {
        if (value <= this.question.colors[0][1]) return this.question.colors[0][0]
        if (value < this.question.colors[1][1] && value > this.question.colors[0][1] ) return 'orange'
        if (value >= this.question.colors[1][1]) return this.question.colors[1][0]
      } else {
        return 'green'
      }
    },
    update () {
      this.$emit('update', {
        property: this.question.value,
        value: this.value
      })
    }
  },
};
</script>

<style>
  .question-card-title {
    word-break: normal;
    font-size: 1.1rem;
  }
</style>
