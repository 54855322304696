<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <h1 class="text-center">Let's manage your well being!</h1>
          <h2 class="text-center">How have you been feeling?</h2>
          <question-card
              v-for="(question, i) in questions"
              :key="i"
              @update="updateHandler"
              :isLoading="isLoading"
              :question="question"
          ></question-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
              block
              depressed
              :disabled="isLoading"
              :loading="isLoading"
              color="primary"
              @click="submit"
              x-large
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import QuestionCard from "../components/dashboard/QuestionCard"

export default {
  name: 'Report',
  components: { QuestionCard },
  data: () => ({
    results: {},
    isLoading: false,
    questions: []
  }),
  created () {
    if (this.status) {
      if (this.status.fallBackToken) {
        this.setAlerts({
          active: true,
          message: 'Looks like you did not respond to the last notification. Tell us how you have been feeling since the last time you submitted your data, and then we will take you to your snapshot page.',
          color: 'secondary'
        })
      }

      if (this.status.metricsToDisplay) {
        this.questions = this.status.metricsToDisplay

        if (Array.isArray(this.questions)) {
          this.results = this.questions.reduce((accumulator, question) => {
            if (question) {
              if (question.value) {
                accumulator[question.value] = 0
              }
            }

            return accumulator
          }, {})
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getToken', 'getStatus']),
    status () {
      if (this.getStatus) {
        return this.getStatus
      } else {
        return {}
      }
    }
  },
  methods: {
    ...mapActions(['updateData', 'setAlerts']),
    updateHandler (answer) {
      if (answer) {
        if (answer.property && answer.value) {
          this.results[answer.property] = answer.value
        }
      }
    },
    async submit () {
      if (this.getToken && this.getStatus) {
        if (this.getToken.length > 0 && Number.isInteger(this.getStatus.index)) {
          this.isLoading = true

          try {
            await this.updateData({
              token: this.getToken,
              ...this.getStatus,
              data: {
                ...this.results
              }
            })

            this.isLoading = false

            this.setAlerts({
              active: false,
              message: '',
              color: ''
            })

            await this.$router.push(`/dashboard?token=${this.getToken}`)
          } catch (error) {
            console.log(error)
            this.isLoading = false
            this.setAlerts({
              active: true,
              message: 'Data update failed, we\'re having issues with our servers, please try again later.',
              color: 'error'
            })
          }
        }
      }
    }
  },
};
</script>
